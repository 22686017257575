import React, { ReactNode } from 'react';
import { format, isValid as isDateValid, parse } from 'date-fns';

import { DATE_FORMAT } from '~/constants/date';
import { PLAN_TYPES, INSURANCE_TYPES } from '~/constants/plan-types';
import { FINANCE_FLOW_TYPE } from '~/constants/flow-types';

import { Main } from './pages';
import { Route } from './services/route/route';
import { FinancingManagement } from './pages/financing-management';
import { ANNOTATION, defineParameter, defineRoute } from './services/route';
import {
  TableColumn,
  SortOrder,
  SortingConfig,
} from './pages/financing-management/list/types';

const rootParams = [ANNOTATION.NAME({ name: 'language', short: 'language' })];

export const APP = defineRoute('', '', {
  params: rootParams,
});

export const ROOT = defineRoute('', ':language', {
  params: rootParams,
});

const defineDateParameter = (
  options: Parameters<typeof defineParameter<Date, string>>[number],
) =>
  defineParameter({
    defaults: null,
    isValid: isDateValid,
    extract: (value) => parse(value, DATE_FORMAT, new Date()),
    archive: (value) => format(new Date(value), DATE_FORMAT),
    ...options,
  });

export const FINANCING_MANAGEMENT = defineRoute(
  ROOT.ROUTE,
  'financing-applications',
  {
    params: rootParams,
    query: [
      ANNOTATION.PAGE({ defaults: 1 }),
      ANNOTATION.PAGE_SIZE({ defaults: 100 }),
      ANNOTATION.NAME({ short: 'on', name: 'orderNumber' }),
      ANNOTATION.NAME({ short: 'sn', name: 'stockNumber' }),
      ANNOTATION.NAME({ short: 'cln', name: 'customerLastName' }),
      ANNOTATION.NAME({ short: 'ce', name: 'customerEmail' }),

      ANNOTATION.ID({
        short: 'ra',
        name: 'riskAgent',
        defaults: null,
      }),
      ANNOTATION.LIST({ name: 'retailCountry' }),
      ANNOTATION.NAME({
        short: 'fft',
        name: 'flowType',
        defaults: null,
        isValid: (value) =>
          Object.values(FINANCE_FLOW_TYPE).includes(value as FINANCE_FLOW_TYPE),
      }),
      defineParameter<string, string>({ name: 'applicationType' }),
      defineParameter<string, string>({ name: 'externalFinancingRefNumber' }),
      ANNOTATION.LIST({ short: 'fs', name: 'status' }),
      ANNOTATION.LIST({ short: 'os', name: 'orderState' }),
      ANNOTATION.LIST({ name: 'paymentStatus' }),
      ANNOTATION.NAME({
        short: 'pt',
        name: 'planType',
        defaults: null,
        isValid: (value) =>
          Object.values(PLAN_TYPES).includes(value as PLAN_TYPES),
      }),

      defineDateParameter({
        short: 'fcos',
        name: 'financingCreatedOnStart',
      }),
      defineDateParameter({
        short: 'fcoe',
        name: 'financingCreatedOnEnd',
      }),

      defineParameter<boolean, string>({
        short: 'insurance',
        name: 'insurance',
        defaults: null,
        isValid: (value) =>
          Object.values(INSURANCE_TYPES).includes(value as INSURANCE_TYPES),
      }),
      ANNOTATION.LIST({ name: 'financingProvider' }),
      ANNOTATION.LIST({ name: 'assignee' }),
      ANNOTATION.LIST({ name: 'salesAgent' }),
      defineParameter<string, string>({ name: 'financingType' }),
      defineParameter<string, string>({ name: 'verificationState' }),
      defineParameter<string, string>({ name: 'bankReleaseSigned' }),
      defineDateParameter({ name: 'handoverDateStart' }),
      defineDateParameter({ name: 'handoverDateEnd' }),
      defineDateParameter({ name: 'bankContractSignedOnStart' }),
      defineDateParameter({ name: 'bankContractSignedOnEnd' }),
      defineDateParameter({ name: 'bankSubmissionDateStart' }),
      defineDateParameter({ name: 'bankSubmissionDateEnd' }),

      defineParameter<SortingConfig, SortingConfig>({
        name: 'sort',
        archive: (sorting) =>
          sorting ? [sorting.order, sorting.column].join(',') : null,
        extract: (raw) => {
          const [order, column] = raw?.split(',') ?? [];

          return order && column
            ? { order: order as SortOrder, column: column as TableColumn }
            : null;
        },
      }),
    ],
  },
);

// PREPARE ROUTES
interface IRoute {
  route: Route;
  link: string;
  element: ReactNode;
}

const routes: Array<IRoute> = [
  {
    route: APP,
    link: APP.LINK(),
    element: <Main />,
  },
  {
    route: FINANCING_MANAGEMENT,
    link: FINANCING_MANAGEMENT.LINK({ language: 'en' }),
    element: <FinancingManagement />,
  },
];

export default routes;
