import { ComponentProps, ReactNode } from 'react';
import {
  DragEndEvent,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  useSortable,
  verticalListSortingStrategy,
  SortableContext as DndSortableContext,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableContextProps {
  children: ReactNode;
  items: string[];
  onDragEnd: (ev: DragEndEvent) => void;
}

export const SortableContext = ({
  children,
  items,
  onDragEnd,
}: SortableContextProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 1 } }),
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <DndSortableContext strategy={verticalListSortingStrategy} items={items}>
        {children}
      </DndSortableContext>
    </DndContext>
  );
};

export const SortableRow = (props: ComponentProps<'tr'>) => {
  const {
    isDragging,
    transition,
    transform,
    setNodeRef,
    attributes,
    listeners,
  } = useSortable({ id: props.id, animateLayoutChanges: () => false });

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={{
        ...props.style,
        cursor: 'move',
        transition,
        transform: CSS.Translate.toString(transform),
        ...(isDragging && { position: 'relative', zIndex: 9999 }),
      }}
      {...attributes}
      {...listeners}
    />
  );
};
