import { useCallback } from 'react';
import { FINANCING_STATUSES } from '@retail/order-financing/src/constants';

import {
  RetailOrderFinanceCancelReason,
  RetailOrderFinanceRejectReason,
  useUpdateOrderFinancingStatusMutation,
} from '~/apollo/gql-types';

export const useUpdateFinancingStatus = () => {
  const [mutate] = useUpdateOrderFinancingStatusMutation();

  return useCallback(
    async ({
      orderId,
      status,
      reason,
    }: {
      orderId: string;
      status: string;
      reason: string | null;
    }) => {
      const { data } = await mutate({
        variables: {
          orderId,
          status,
          cancelReason:
            status === FINANCING_STATUSES.CANCELED
              ? (reason as RetailOrderFinanceCancelReason)
              : null,
          rejectReason:
            status === FINANCING_STATUSES.REJECTED
              ? (reason as RetailOrderFinanceRejectReason)
              : null,
        },
      });

      return data.isUpdated;
    },
    [mutate],
  );
};
