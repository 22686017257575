import { FINANCING_STATUSES } from '@retail/order-financing/src/constants/financingStatuses';

export const financingApplicationStatuses = [
  {
    value: FINANCING_STATUSES.PENDING,
    label: 'Pending',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.VALIDATION,
    label: 'Validation',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
    label: 'Customer action required',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.SUBMITTED_TO_BANK,
    label: 'Submitted to bank',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.PRE_APPROVED,
    label: 'Pre-approved',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.APPROVED,
    label: 'Approved',
    color: 'success',
  },
  {
    value: FINANCING_STATUSES.REJECTED,
    label: 'Rejected',
    color: 'danger',
  },
  {
    value: FINANCING_STATUSES.CANCELED,
    label: 'Canceled',
    color: 'default',
  },
  {
    value: FINANCING_STATUSES.COMPLETED,
    label: 'Completed',
    color: 'default',
  },
];
