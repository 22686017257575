export enum PLAN_TYPES {
  CLASSIC = 'CLASSIC',
  BALLOON = 'BALLOON',
  CREDIT = 'Credit',
  FINANCIAL_LEASE = 'Financial Lease',
  CLASSIC_WITHOUT_DISCOUNT = 'Credit - Without discount',
  CLASSIC_WITH_DISCOUNT = 'Credit - With discount',
}

export enum INSURANCE_TYPES {
  PPI = 'ppi',
  GAP = 'gap',
  PPI_GAP = 'ppi+gap',
  NO_INSURANCE = 'no-insurance',
}

export const planTypesOptions = [
  { value: PLAN_TYPES.CLASSIC, label: 'Classic' },
  { value: PLAN_TYPES.BALLOON, label: 'Balloon' },
  { value: PLAN_TYPES.CREDIT, label: 'Credit' },
  { value: PLAN_TYPES.FINANCIAL_LEASE, label: 'Financial lease' },
  {
    value: PLAN_TYPES.CLASSIC_WITHOUT_DISCOUNT,
    label: 'Classic without discount',
  },
  {
    value: PLAN_TYPES.CLASSIC_WITH_DISCOUNT,
    label: 'Classic with discount',
  },
];

export const insuranceOptions = [
  { value: 'ppi', label: 'PPI' },
  { value: 'gap', label: 'GAP' },
  { value: 'ppi+gap', label: 'PPI+GAP' },
  { value: 'no-insurance', label: 'No Insurance' },
];
