import {
  useUpdateExternalFinancingRefNumberMutation,
  UpdateExternalFinancingRefNumberMutationVariables as Variables,
} from '~/apollo/gql-types';

export const useUpdateExternalFinancingRefNumber = () => {
  const [mutate] = useUpdateExternalFinancingRefNumberMutation();

  return async (variables: Variables) => {
    const { data } = await mutate({ variables });

    return data?.options;
  };
};
