import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { useGeneratePdfDocumentMutation } from '~/apollo/gql-types';
import { PermissionChecker } from '~/components/permission-checker';
import { PERMISSION } from '~/constants/permission';
import { DOMAIN } from '~/constants/domain';

type Props = {
  orderId: string;
};

export const GeneratePdfButton = ({ orderId }: Props) => {
  const [mutate] = useGeneratePdfDocumentMutation({
    context: {
      headers: {
        'access-domain': DOMAIN.ORDER_FINANCING_RISK_MANAGEMENT,
      },
    },
    variables: {
      orderId,
      documentTypeKey: 'DOCUMENT_FINANCE_TYPE_RISK_SCORECARD',
    },
  });

  return (
    <PermissionChecker
      allow={PERMISSION.MUTATION_GENERATE_PDF_DOCUMENT}
      domain={DOMAIN.ORDER_FINANCING_RISK_MANAGEMENT}
      fallback={null}
    >
      <Button
        type="primary"
        icon={<EyeOutlined />}
        onClick={async () => {
          const { data } = await mutate();
          const { presignedUrl } = data?.generatePdfDocument ?? {};

          if (presignedUrl) {
            window.location.replace(presignedUrl);
          }
        }}
      />
    </PermissionChecker>
  );
};
