import { MinusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

import { PopoverForm } from './popover-form';

interface FormValues {
  refNumber: string;
}

interface ExternalFinancingRefNumberProps {
  externalFinancingRefNumber: undefined | null | string;
  qaSelector?: string;
  allowUpdate: boolean;
  onSave: (refNumber: string) => Promise<unknown>;
}

export const ExternalFinancingRefNumber = ({
  externalFinancingRefNumber,
  qaSelector,
  allowUpdate,
  onSave,
}: ExternalFinancingRefNumberProps) => {
  const [formInstance] = Form.useForm<FormValues>();

  const handleSubmit = async ({ refNumber }: FormValues) => onSave(refNumber);

  if (!allowUpdate) {
    return (
      <span data-qa-selector={qaSelector}>
        {externalFinancingRefNumber ?? <MinusOutlined />}
      </span>
    );
  }

  return (
    <span data-qa-selector={qaSelector}>
      <PopoverForm
        formInstance={formInstance}
        title="Update ext. financing ref #"
        onSubmit={handleSubmit}
        onPopoverVisibilityChange={(isVisible) => {
          if (isVisible) {
            formInstance.setFieldsValue({
              refNumber: externalFinancingRefNumber,
            });
          }
        }}
        formFields={
          <Form.Item name="refNumber" rules={[{ required: true }]} required>
            <Input
              data-qa-selector="ref-number-input"
              placeholder="Ref #"
              autoComplete="off"
            />
          </Form.Item>
        }
      >
        <Button size="small">
          {externalFinancingRefNumber ?? 'Add ext. financing ref #'}
        </Button>
      </PopoverForm>
    </span>
  );
};
