import { useMemo } from 'react';

import useUser from '../useUser';

import { hasPermissions, getGQLPermissions } from './helpers';

export interface IUseCheckPermissionsProps {
  allow: string | string[];
  domain: string | string[];
}

export interface IUseCheckPermissions {
  isAllowed: boolean;
  isDenied: boolean;
  isLoading: boolean;
}

const useCheckPermissions = ({
  allow,
  domain,
}: IUseCheckPermissionsProps): IUseCheckPermissions => {
  const permissionsData = useUser();
  return useMemo(() => {
    const { permissions, isLoaded } = permissionsData;
    const allowPerms = getGQLPermissions(allow, domain);
    const isValid = hasPermissions(permissions, allowPerms);

    return {
      isAllowed: isLoaded && isValid,
      isDenied: isLoaded && !isValid,
      isLoading: !isLoaded,
    };
  }, [allow, domain, permissionsData]);
};

export default useCheckPermissions;
