interface WithName {
  firstName?: null | string;
  lastName?: null | string;
}

const capitalize = (value: string): string =>
  value && value[0].toUpperCase() + value.slice(1);

export const formatFullName = (it: WithName): undefined | string =>
  [it.firstName, it.lastName].filter(Boolean).map(capitalize).join(' ') ||
  undefined;
