import { useMemo } from 'react';

import {
  FetchCommentsQuery,
  AddCommentMutation,
  FetchCommentsDocument,
  useFetchCommentsQuery,
  useAddCommentMutation,
} from '~/apollo/gql-types';

type Comment = FetchCommentsQuery['output']['comments'][number];

type CreatedComment = AddCommentMutation['created'];

interface UseCommentsInput {
  orderId: string;
  financingId: string;
}

const sortComments = (list: Comment[]) =>
  Array.from(list).sort((a, b) =>
    a.date && b.date
      ? new Date(b.date).getTime() - new Date(a.date).getTime()
      : 0,
  );

const addCreatedCommentToQuery = (
  created: CreatedComment,
  query: FetchCommentsQuery,
): FetchCommentsQuery => {
  const comment: Comment = {
    __typename: 'CommentProjection',
    id: created.id,
    comment: created.message,
    date: created.createdOn,
    createdBy: created.createdBy && {
      __typename: 'UserProjection',
      id: created.createdBy.id,
      firstName: created.createdBy.firstName,
      lastName: created.createdBy.lastName,
    },
  };

  return {
    ...query,
    output: query.output && {
      ...query.output,
      comments: query.output?.comments && [comment, ...query.output.comments],
    },
  };
};

export const useComments = ({ orderId, financingId }: UseCommentsInput) => {
  const variables = { orderId, financingId };
  const {
    client,
    loading: isLoading,
    data,
  } = useFetchCommentsQuery({ variables });
  const [addCommentMutation, { loading: isCommentAdding }] =
    useAddCommentMutation();
  const list = useMemo(
    () => data?.output?.comments && sortComments(data.output.comments),
    [data],
  );

  const addComment = async ({ comment }: Pick<Comment, 'comment'>) => {
    const {
      data: { created },
    } = await addCommentMutation({
      variables: { orderId, comment },
    });

    if (!created) {
      throw new Error('Unable to create a comment');
    }

    client.cache.updateQuery<FetchCommentsQuery>(
      { query: FetchCommentsDocument, variables },
      (query) => query && addCreatedCommentToQuery(created, query),
    );
  };

  return {
    isLoading,
    isCommentAdding,
    list,
    addComment,
  };
};
