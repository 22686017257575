import { FilterType } from './constants';

type Filter = {
  field: string;
  op: FilterType;
  value: string;
};

export const createFilter = (
  field: string,
  op: FilterType,
  value = null,
): Filter => ({
  field,
  op,
  value,
});

type Sort = { property: string; direction: 'ASC' | 'DESC' };

export const createSort = (
  property: string,
  direction: 'ascend' | 'descend',
): Sort => ({
  property,
  direction: direction === 'ascend' ? 'ASC' : 'DESC',
});

type FilterPayload = {
  filter?: Filter;
  pageSize?: number;
  page?: number;
  sorts?: Array<Sort>;
};

export const createFilterPayload = ({
  filter = null,
  pageSize,
  sorts = [],
  page,
  ...rest
}: FilterPayload) => ({ filter, page, pageSize, sorts, ...rest });
