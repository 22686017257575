// eslint-disable-next-line no-restricted-imports
import {
  intersection,
  isEmpty,
  size,
  last,
  includes,
  without,
  flow,
  filter,
  get,
  map,
} from 'lodash/fp';

import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';

const minSize = 2;
const modifiers = [
  AUX_USER_ACCESS_PARAM_VALUE.NONE,
  AUX_USER_ACCESS_PARAM_VALUE.ALL,
];

export const parseAccessParams = (value) => {
  if (isEmpty(value) || size(value) < minSize) {
    return value;
  }

  const newValue = last(value);

  if (includes(newValue, modifiers)) {
    return [newValue];
  }

  if (intersection(modifiers, value)) {
    return without(modifiers, value);
  }

  return value;
};

export const hasAccess = (value: string, userAccess: unknown[]): boolean => {
  if (includes(AUX_USER_ACCESS_PARAM_VALUE.ALL, userAccess)) {
    return true;
  }

  if (includes(AUX_USER_ACCESS_PARAM_VALUE.NONE, userAccess)) {
    return false;
  }

  return includes(value, userAccess);
};

export const getUserAccess = (key) => {
  return flow(filter({ key }), map(get(['value'])));
};
