import retailGlobal from '@retail/global';
import * as retailDotenv from '@retail/dotenv';
import { loadConfig } from '@retail/config';
import { i18nextInitWithDefaults } from '@retail/backoffice-i18next';

if (__WEBPACK_DEV_SERVER__) {
  // eslint-disable-next-line global-require
  require('antd/dist/antd.less');
}

const ENV = __WEBPACK_DEV_SERVER__
  ? process.env.ENV
  : retailGlobal.get<{ ENV?: string }>('__config')?.ENV;

loadConfig(retailDotenv.load(ENV));

i18nextInitWithDefaults();
