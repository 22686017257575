import React, { createContext, Context, PropsWithChildren } from 'react';

import {
  AccessParametersFragmentFragment,
  useUserInfoQuery,
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export interface IUserContext {
  isLoaded: boolean;
  permissions: Array<string> | null;
  accessParameters: Array<AccessParametersFragmentFragment> | null;
  id: number | null;
}

export const UserContext: Context<IUserContext> = createContext({
  isLoaded: false,
  permissions: null,
  accessParameters: null,
  id: null,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data } = useUserInfoQuery(REQUEST_BASE_OPTIONS);
  const user = data?.me;
  const isLoaded = user?.id ?? false;

  if (!isLoaded) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        id: user?.id,
        isLoaded,
        permissions: user?.effectivePermissions,
        accessParameters: user?.accessParameters,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
