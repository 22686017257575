/* eslint-disable no-unused-vars */

export const enum FilterType {
  OR = 'or',
  AND = 'and',
  NOT = 'not',
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  IN = 'in',
  LIKE = 'like',
  IS_NULL = 'is_null',
  IS_NOT_NULL = 'is_not_null',
  LESS_THAN_OR_EQUAL = 'le',
  LESS_THAN = 'lt',
  GREATER_THAN_OR_EQUAL = 'ge',
  GREATER_THAN = 'gt',
}

export const enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const MAX_PAGE_SIZE = 2147483647;

export const EMPTY_UUID = '111111111-1111-1111-1111-11111111111';
