// eslint-disable-next-line no-restricted-imports
import sortBy from 'lodash/sortBy';

import { ColumnConfig, TableColumn } from '../types';

const COLUMNS_CONFIG_KEY = 'financingColumnsConfig';

interface WithUserId {
  userId: string;
}

interface PersistColumnsInput extends WithUserId {
  columns: TableColumn[];
}

const getColumnsConfigKey = (userId: string) =>
  `${COLUMNS_CONFIG_KEY}:${userId}`;

export const getColumnsConfig = (
  visibleColumns: TableColumn[],
): ColumnConfig[] => {
  const allColumns = Object.values(TableColumn);

  // Display visible first
  return sortBy(allColumns, (column) => {
    const index = visibleColumns.indexOf(column);

    return index !== -1 ? index : allColumns.length;
  }).map<ColumnConfig>((column) => ({
    column,
    isVisible: visibleColumns.includes(column),
  }));
};

export const getVisibleColumns = (config: ColumnConfig[]): TableColumn[] =>
  config.filter((it) => it.isVisible).map((it) => it.column);

export const persistColumns = ({ userId, columns }: PersistColumnsInput) => {
  localStorage.setItem(getColumnsConfigKey(userId), JSON.stringify(columns));
};

export const getPersistedColumns = ({
  userId,
}: WithUserId): null | TableColumn[] =>
  JSON.parse(localStorage.getItem(getColumnsConfigKey(userId)));
