import { Typography, Table, Tag, Tooltip, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import {
  useSearchAxaPolicyNotificationsQuery,
  usePushAxaPolicyNotificationMutation,
} from '~/apollo/gql-types';
import { PERMISSION } from '~/constants/permission';
import { DOMAIN } from '~/constants/domain';
import useCheckPermissions from '~/hooks/acl/useCheckPermissions';
import { formatDateTime } from '~/utils/date';
import {
  createFilter,
  createFilterPayload,
  FilterType,
} from '~/utils/gql-helpers';

import cn from './styles.less';

enum NotificationStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

interface SectionProps {
  orderId: string;
}

export function Section({ orderId }: SectionProps) {
  const { t } = useTranslation();
  const { loading, data } = useSearchAxaPolicyNotificationsQuery({
    variables: {
      search: createFilterPayload({
        filter: createFilter('orderId', FilterType.EQUAL, orderId),
        page: 0,
        pageSize: 20,
      }),
    },
  });
  const notifications = data?.searchAxaPolicyNotifications?.entities ?? [];

  const { isAllowed: hasExportPermission } = useCheckPermissions({
    allow: PERMISSION.MUTATION_PUSH_AXA_POLICY_NOTIFICATION,
    domain: DOMAIN.ORDER_MANAGEMENT,
  });
  const [exportNotification, { loading: isExporting }] =
    usePushAxaPolicyNotificationMutation({
      update(cache, { data: { notification } }) {
        cache.modify({
          id: cache.identify(notification),
          fields: (_, { fieldName }) => notification[fieldName],
        });
      },
    });

  return (
    <div className={cn.section}>
      <Typography.Title level={5}>
        {t('bo.orderConsumerFinancing.axaPolicy.sectionHeading')}
      </Typography.Title>
      <Table
        rowKey="id"
        size="small"
        pagination={false}
        loading={loading}
        dataSource={notifications}
        columns={useMemo(
          () => [
            {
              title: t('bo.orderConsumerFinancing.axaPolicy.table.policyType'),
              dataIndex: 'policyType',
            },
            {
              title: t(
                'bo.orderConsumerFinancing.axaPolicy.table.notificationType',
              ),
              dataIndex: 'notificationType',
            },
            {
              title: t('bo.orderConsumerFinancing.axaPolicy.table.exportedOn'),
              dataIndex: 'exportedOn',
              render: (exportedOn) => formatDateTime(exportedOn) || '-',
            },
            {
              title: t('bo.orderConsumerFinancing.axaPolicy.table.status'),
              dataIndex: 'status',
              render: (status) => (
                <Tag
                  color={
                    status === NotificationStatus.Failed ? 'error' : 'success'
                  }
                >
                  {status}
                </Tag>
              ),
            },
            {
              dataIndex: 'actions',
              width: '50px',
              render: (_, notification) =>
                hasExportPermission &&
                notification.status === NotificationStatus.Failed ? (
                  <Tooltip
                    title={t(
                      'bo.orderConsumerFinancing.axaPolicy.table.exportButton.tooltip',
                    )}
                  >
                    <Button
                      data-qa-selector="export-axa-notification-button"
                      data-qa-selector-value={notification.id}
                      icon={<ReloadOutlined />}
                      disabled={isExporting}
                      onClick={() =>
                        exportNotification({
                          variables: { notificationId: notification.id },
                        })
                      }
                    />
                  </Tooltip>
                ) : null,
            },
          ],
          [t, hasExportPermission, isExporting, exportNotification],
        )}
      />
    </div>
  );
}
