import { Tag } from 'antd';

import { retailOrderOptions } from '~/constants/order-states';

type Props = {
  state: string;
  qaSelector?: string;
};

const StateToText = retailOrderOptions.reduce((acc, curr) => {
  acc[curr.value] = curr.label;
  return acc;
}, {});

export const OrderState = ({ state, qaSelector }: Props) => (
  <Tag color="#222" data-qa-selector={qaSelector}>
    {StateToText[state]}
  </Tag>
);
