import { HTMLAttributes, useState, useEffect } from 'react';
import formatDate from 'date-fns/format';
import { Typography, Table, Form, Button, Input } from 'antd';

import { DATE_TIME_FORMAT } from '~/constants/date';

import { formatFullName } from '../utils';
import { useComments } from '../hooks/useComments';

interface WithComment {
  comment: string;
}

interface CommentsProps {
  orderId: string;
  financingId: string;
}

const qaSelectorOnCell = (qaSelector: string) => () =>
  ({
    'data-qa-selector': qaSelector,
  }) as HTMLAttributes<HTMLElement>;

export const Comments = ({ orderId, financingId }: CommentsProps) => {
  const [showCommentForm, setShowCommentForm] = useState(false);
  const comments = useComments({ orderId, financingId });
  const commentCount = comments.list?.length;

  const handleCommentSubmit = async (submitted: WithComment) => {
    await comments.addComment(submitted);
    setShowCommentForm(false);
  };

  useEffect(() => {
    if (commentCount === 0) {
      setShowCommentForm(true);
    }
  }, [commentCount]);

  return (
    <div style={{ width: 'calc(100vw - 80px)' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>
          {commentCount != null ? `Comments (${commentCount})` : 'Comments'}
        </Typography.Title>
        {!comments.isLoading && !showCommentForm && (
          <Button
            type="primary"
            data-qa-selector="comments-add-comment"
            onClick={() => setShowCommentForm(true)}
          >
            Add comment
          </Button>
        )}
      </div>
      {showCommentForm && (
        <Form<WithComment>
          style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
          onFinish={handleCommentSubmit}
        >
          <Form.Item
            name="comment"
            style={{ marginBottom: 16 }}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input.TextArea
              autoFocus
              placeholder="Write your comment here..."
              data-qa-selector="comments-form-input"
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
            <Button
              data-qa-selector="comments-form-cancel"
              onClick={() => setShowCommentForm(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={comments.isCommentAdding}
              data-qa-selector="comments-form-submit"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
      <Table
        rowKey="id"
        pagination={false}
        columns={[
          {
            key: 'date',
            title: 'Date',
            dataIndex: 'date',
            render: (_, it) =>
              it.date && formatDate(new Date(it.date), DATE_TIME_FORMAT),
            onCell: qaSelectorOnCell('comments-table-cell-date'),
          },
          {
            key: 'from',
            title: 'From',
            render: (_, it) => it.createdBy && formatFullName(it.createdBy),
            onCell: qaSelectorOnCell('comments-table-cell-created-by'),
          },
          {
            key: 'comment',
            title: 'Comment',
            render: (_, it) => (
              <pre
                style={{
                  background: 'none',
                  border: 'none',
                  margin: 0,
                  padding: 0,
                }}
                data-qa-selector="comments-table-cell-comment"
              >
                {it.comment}
              </pre>
            ),
          },
        ]}
        loading={comments.isLoading}
        dataSource={comments.list}
      />
    </div>
  );
};
