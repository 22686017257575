export interface Price {
  currencyCode: string;
  amountMinorUnits: number;
}

const formatterMap: Record<string, Intl.NumberFormat> = {};

export const formatCurrency = (
  country: string,
  { currencyCode, amountMinorUnits: minor }: Price,
) =>
  (formatterMap[country] ??= new Intl.NumberFormat(country, {
    style: 'currency',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    currency: currencyCode,
  })).format(minor / 100);
