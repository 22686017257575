import { GeneratePdfButton } from './generate-pdf-button';

type Props = {
  orderId: string;
};

export const TaskActions = ({ orderId }: Props) => {
  return (
    <>
      <GeneratePdfButton orderId={orderId} />
    </>
  );
};
