export enum DOMAIN {
  BO_MANAGEMENT = 'bo.management',
  DEFAULT = BO_MANAGEMENT,
  CLM_API = '{platform}.api',
  CLM_GRANT = '{platform}.grant',
  ORDER_MANAGEMENT = 'order.management',
  PLATFORM_API = '{platform}.api',
  ORDER_FINANCING_RISK_MANAGEMENT = 'order.financing.risk.management',
  RISK_EVALUATION = ORDER_FINANCING_RISK_MANAGEMENT,
}
