import { FINANCING_STATUSES } from '@retail/order-financing/src/constants';
import { Form, Select, Space } from 'antd';
import { useEffect } from 'react';

import {
  useFetchFinancingCancelReasonsQuery,
  useFetchFinancingRejectReasonsQuery,
} from '~/apollo/gql-types';
import { financingApplicationStatuses } from '~/constants/financing-application-statuses';
import { addQaIdForSelectOptions, selectQaSelectors } from '~/utils/antd';

import { FormValues } from './types';

interface FormFieldsProps {
  currentStatus: string;
  financingType: string;
  availableStatuses: string[];
  cancelReason: string;
  rejectReason: string;
}

export const FormFields = ({
  currentStatus,
  financingType,
  availableStatuses: statuses,
  cancelReason,
  rejectReason,
}: FormFieldsProps) => {
  const statusOptions = addQaIdForSelectOptions(
    (statuses.includes(currentStatus)
      ? statuses
      : [currentStatus, ...statuses]
    ).map(
      (status) =>
        financingApplicationStatuses.find((it) => it.value === status) ?? {
          value: status,
          label: status,
        },
    ),
  );

  const newStatus = Form.useWatch<string>('status');

  const cancelReasonsQuery = useFetchFinancingCancelReasonsQuery({
    skip: newStatus !== FINANCING_STATUSES.CANCELED,
    fetchPolicy: 'cache-first',
  });

  const rejectReasonsQuery = useFetchFinancingRejectReasonsQuery({
    skip: newStatus !== FINANCING_STATUSES.REJECTED,
    variables: {
      financingType,
    },
    fetchPolicy: 'cache-first',
  });

  const formInstance = Form.useFormInstance<FormValues>();

  useEffect(() => {
    const reason =
      {
        [FINANCING_STATUSES.CANCELED]: cancelReason,
        [FINANCING_STATUSES.REJECTED]: rejectReason,
      }[newStatus] ?? null;

    formInstance.setFieldsValue({ reason });
  }, [formInstance, newStatus, cancelReason, rejectReason]);

  const reasonsQuery = {
    [FINANCING_STATUSES.CANCELED]: cancelReasonsQuery,
    [FINANCING_STATUSES.REJECTED]: rejectReasonsQuery,
  }[newStatus];

  return (
    <Space style={{ width: '450px' }} direction="vertical">
      <Form.Item
        name="status"
        label="New status"
        rules={[{ required: true }]}
        required
      >
        <Select options={statusOptions} {...selectQaSelectors('new-status')} />
      </Form.Item>
      {reasonsQuery && (
        <Form.Item
          name="reason"
          label="Reason"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={addQaIdForSelectOptions(
              reasonsQuery.data?.reasons?.map((it) => ({
                value: it.key,
                label: it.description,
              })) ?? [],
            )}
            loading={reasonsQuery.loading}
            disabled={reasonsQuery.loading}
            {...selectQaSelectors('reason-for-status')}
          />
        </Form.Item>
      )}
    </Space>
  );
};
