import { NEW_ID } from './constants';
import { ParameterOptions } from './parameter';

import { defineParameter } from './index';

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**
 * Some commonly used annotations
 */
export const ANNOTATION = {
  // NOTE popular params
  ID: (options: Partial<ParameterOptions<number, string>>) =>
    defineParameter<number, string>({
      name: 'id',
      defaults: NEW_ID,
      ...options,
    }),
  TOKEN: (options: Partial<ParameterOptions<string, string>>) =>
    defineParameter<string, string>({
      name: 'token',
      defaults: 'invalid-token',
      ...options,
    }),
  // NOTE popular query
  SEARCH: (options: Partial<ParameterOptions<string, string>>) =>
    defineParameter<string, string>({
      short: 's',
      name: 'search',
      defaults: '',
      ...options,
    }),
  NAME: (options: Partial<ParameterOptions<string, string>>) =>
    defineParameter<string, string>({
      short: 'n',
      name: 'name',
      defaults: '',
      ...options,
    }),
  PAGE: (options: Partial<ParameterOptions<number, number>>) =>
    defineParameter<number, number>({
      short: 'p',
      name: 'page',
      defaults: 0,
      archive: String,
      extract: Number,
      ...options,
    }),
  PAGE_SIZE: (options: Partial<ParameterOptions<number, number>>) =>
    defineParameter<number, number>({
      short: 's',
      name: 'pageSize',
      defaults: 100,
      archive: String,
      extract: Number,
      ...options,
    }),
  SORT_DIRECTION: (
    options: Partial<ParameterOptions<SortDirection, SortDirection>>,
  ) =>
    defineParameter<SortDirection, SortDirection>({
      short: 'sd',
      name: 'sortDirection',
      defaults: SortDirection.Desc,
      archive: (value: SortDirection) => String(value),
      extract: (value: string) => value as SortDirection,
      ...options,
    }),
  SORT_FIELD: (options: Partial<ParameterOptions<string, string>>) =>
    defineParameter<string, string>({
      short: 'sf',
      name: 'property',
      defaults: 'name',
      isValid: (value: string) => ['name'].includes(value),
      ...options,
    }),
  LIST: (options: Partial<ParameterOptions<string[], string[]>>) =>
    defineParameter<string[], string[]>({
      name: 'list',
      defaults: [],
      isValid: (value: unknown): value is string[] =>
        Array.isArray(value) && value.every((it) => typeof it === 'string'),

      extract: (raw) => raw?.split(',') ?? [],
      archive: (list) => list?.join(','),
      ...options,
    }),
  // Complex types
  // STATUSES: (options: Partial<ParameterOptions<Array<string>, Array<string>>>) =>
  // defineParameter<Array<string>, Array<string>>({
  //   short: 'st',
  //   name: 'statuses',
  //   defaults: [],
  //   isValid: (value: Array<string>) => yup.array()
  //     .of(
  //       yup.string()
  //         .required()
  //         .oneOf(Object.values(ENTITY_STATUS))
  //     )
  //     .required()
  //     .isValidSync(value),
  //   ...options
  // }),
};
