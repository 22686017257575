import { AccessParametersFragmentFragment } from '~/apollo/gql-types';
import { getUserAccess, hasAccess } from '~/hooks/acl/helpers/accessParams';

export enum Country {
  AT = 'AT',
  BE = 'BE',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
  NL = 'NL',
  PL = 'PL',
  SE = 'SE',
}

export const COUNTRY_LABELS: Record<Country, string> = {
  [Country.AT]: 'Austria',
  [Country.BE]: 'Belgium',
  [Country.DE]: 'Germany',
  [Country.ES]: 'Spain',
  [Country.FR]: 'France',
  [Country.IT]: 'Italy',
  [Country.NL]: 'Netherlands',
  [Country.PL]: 'Poland',
  [Country.SE]: 'Sweden',
};

export const getUserAccessibleCountryCodeOptions = (
  accessParameters: AccessParametersFragmentFragment[],
) => {
  const userAccess = getUserAccess('country')(accessParameters);

  return Object.values(Country)
    .filter((it) => hasAccess(it, userAccess))
    .map((it) => ({
      value: it,
      label: COUNTRY_LABELS[it],
    }));
};
