export enum FINANCE_FLOW_TYPE {
  AH_DIGITAL = 'AH_DIGITAL',
  AH_MANUAL = 'AH_MANUAL',
  MANUAL = 'MANUAL',
}

export const financeFlowOptions = [
  {
    label: 'AH digital',
    value: FINANCE_FLOW_TYPE.AH_DIGITAL,
  },
  {
    label: 'AH manual',
    value: FINANCE_FLOW_TYPE.AH_MANUAL,
  },
  {
    label: 'Manual',
    value: FINANCE_FLOW_TYPE.MANUAL,
  },
];
