import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { jwtDecode } from 'jwt-decode';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import typePolicies from './policies';

export interface AccessTokenPayload {
  user_uuid?: string;
}

export function getCookieByName(name, context = document.cookie) {
  const value = `; ${context}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

export const getUserId = () => {
  const accessToken = getCookieByName('t');

  return accessToken && jwtDecode<AccessTokenPayload>(accessToken)?.user_uuid;
};

const httpLink = createHttpLink({
  uri: `/${process.env.API_URL}`,
  fetch,
});

// NOTE Prepare common headers for all requests
const authLink = new ApolloLink((operation, forward) => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them

  const context = operation.getContext();

  operation.setContext({
    headers: {
      accept: 'application/json, text/plain, */*',
      'access-domain': process.env.SCHEMA_ACCESS_DOMAIN,
      'content-type': 'application/json;charset=UTF-8',
      authorization: `${process.env.SCHEMA_AUTH_TITLE} ${accessToken}`,
      ...context.headers,
    },
  });

  return forward(operation);
});

// NOTE Apollo client
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, onError(handleErrors), httpLink]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export function handleErrors({ networkError, operation, forward }) {
  if (networkError?.statusCode === 401) {
    const accessToken = getCookieByName('t');
    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return forward(operation);
  }
}
