import { useAssignFinancingAgentMutation } from '~/apollo/gql-types';

interface AssignFinancingAgentInput {
  financingId: string;
  financingAgentId: undefined | null | string;
}

export const useFinancingAgent = () => {
  const [mutate] = useAssignFinancingAgentMutation({
    ignoreResults: true,
  });

  const assignFinancingAgent = async ({
    financingId,
    financingAgentId = null,
  }: AssignFinancingAgentInput) => {
    await mutate({ variables: { financingId, financingAgentId } });
  };

  return {
    assignFinancingAgent,
  };
};
