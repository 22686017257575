import { isValid, parseISO } from 'date-fns';

import { isMobileSafari, isSafari } from '../browser';

/**
 * Do not change unnecessarily, those functions affects performance
 */
const defaultParseDate = (date) =>
  typeof date === 'string' ? Date.parse(date) : date;

const safariParseDate = (rawDate) => {
  if (typeof rawDate !== 'string') {
    return rawDate;
  }

  const date = parseISO(rawDate);
  return isValid(date) ? +date : NaN;
};

export const createParseDate = () =>
  isSafari || isMobileSafari ? safariParseDate : defaultParseDate;
