// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#\\-2WK-o\\+r9lvSGIQ-kU\\+-Qg\\=\\= {\n  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;\n}\n#\\-2WK-o\\+r9lvSGIQ-kU\\+-Qg\\=\\= .PDxibfiF55vsBkP1bTaF1Q\\=\\= {\n  font-weight: normal;\n}\n.ant-notification {\n  z-index: 1060 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/root/styles.less"],"names":[],"mappings":"AACA;EACE,oLAAA;AAAF;AADA;EAgBI,mBAAA;AAZJ;AAgBA;EAEI,wBAAA;AAfJ","sourcesContent":["@antVersion: antd-4-21-7;\n#financing-management {\n  font-family:\n    -apple-system,\n    BlinkMacSystemFont,\n    Segoe UI,\n    Roboto,\n    Helvetica Neue,\n    Arial,\n    Noto Sans,\n    sans-serif,\n    Apple Color Emoji,\n    Segoe UI Emoji,\n    Segoe UI Symbol,\n    Noto Color Emoji;\n\n  .label {\n    font-weight: normal;\n  }\n}\n\n:global {\n  .ant-notification {\n    z-index: 1060 !important;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"financing-management": "-2WK-o+r9lvSGIQ-kU+-Qg==",
	"label": "PDxibfiF55vsBkP1bTaF1Q=="
};
export default ___CSS_LOADER_EXPORT___;
