export enum RETAIL_ORDER_STATE {
  INCOMPLETE = 'INCOMPLETE',
  DRAFT = 'DRAFT',
  CONTRACT_SENT = 'CONTRACT_SENT',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export const retailOrderOptions = [
  { value: RETAIL_ORDER_STATE.INCOMPLETE, label: 'Incomplete' },
  { value: RETAIL_ORDER_STATE.DRAFT, label: 'Draft' },
  { value: RETAIL_ORDER_STATE.CONTRACT_SENT, label: 'Contract Sent' },
  { value: RETAIL_ORDER_STATE.CONTRACT_SIGNED, label: 'Contract Signed' },
  {
    value: RETAIL_ORDER_STATE.PENDING_VERIFICATION,
    label: 'Pending Verification',
  },
  { value: RETAIL_ORDER_STATE.VERIFIED, label: 'Verified' },
  { value: RETAIL_ORDER_STATE.DELIVERED, label: 'Delivered' },
  { value: RETAIL_ORDER_STATE.COMPLETED, label: 'Completed' },
  { value: RETAIL_ORDER_STATE.CANCELED, label: 'Canceled' },
];
