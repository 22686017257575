import { FINANCING_STATUSES } from '@retail/order-financing/src/constants';

type StatusCondition =
  | {
      nextStatuses: string[];
    }
  | {
      previousStatuses: string[];
      nextStatuses: string[];
    }[];

const transitionMap: Record<string, StatusCondition> = {
  [FINANCING_STATUSES.PENDING]: { nextStatuses: [] },
  [FINANCING_STATUSES.VALIDATION]: {
    nextStatuses: [
      FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
      FINANCING_STATUSES.SUBMITTED_TO_BANK,
      FINANCING_STATUSES.CANCELED,
      FINANCING_STATUSES.REJECTED,
    ],
  },
  [FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED]: [
    {
      previousStatuses: [FINANCING_STATUSES.VALIDATION],
      nextStatuses: [
        FINANCING_STATUSES.VALIDATION,
        FINANCING_STATUSES.CANCELED,
      ],
    },
    {
      previousStatuses: [FINANCING_STATUSES.SUBMITTED_TO_BANK],
      nextStatuses: [
        FINANCING_STATUSES.SUBMITTED_TO_BANK,
        FINANCING_STATUSES.CANCELED,
      ],
    },
    {
      previousStatuses: [FINANCING_STATUSES.PRE_APPROVED],
      nextStatuses: [
        FINANCING_STATUSES.PRE_APPROVED,
        FINANCING_STATUSES.CANCELED,
      ],
    },
    {
      previousStatuses: [FINANCING_STATUSES.APPROVED],
      nextStatuses: [FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.CANCELED],
    },
  ],
  [FINANCING_STATUSES.SUBMITTED_TO_BANK]: {
    nextStatuses: [
      FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
      FINANCING_STATUSES.APPROVED,
      FINANCING_STATUSES.PRE_APPROVED,
      FINANCING_STATUSES.CANCELED,
      FINANCING_STATUSES.REJECTED,
    ],
  },
  [FINANCING_STATUSES.APPROVED]: {
    nextStatuses: [
      FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
      FINANCING_STATUSES.PRE_APPROVED,
      FINANCING_STATUSES.CANCELED,
      FINANCING_STATUSES.REJECTED,
    ],
  },
  [FINANCING_STATUSES.PRE_APPROVED]: {
    nextStatuses: [
      FINANCING_STATUSES.CUSTOMER_ACTION_REQUIRED,
      FINANCING_STATUSES.APPROVED,
      FINANCING_STATUSES.CANCELED,
      FINANCING_STATUSES.REJECTED,
    ],
  },
  [FINANCING_STATUSES.REJECTED]: {
    nextStatuses: [
      FINANCING_STATUSES.VALIDATION,
      FINANCING_STATUSES.SUBMITTED_TO_BANK,
      FINANCING_STATUSES.PRE_APPROVED,
      FINANCING_STATUSES.APPROVED,
      FINANCING_STATUSES.CANCELED,
    ],
  },
  [FINANCING_STATUSES.CANCELED]: {
    nextStatuses: [
      FINANCING_STATUSES.VALIDATION,
      FINANCING_STATUSES.SUBMITTED_TO_BANK,
      FINANCING_STATUSES.PRE_APPROVED,
      FINANCING_STATUSES.APPROVED,
      FINANCING_STATUSES.REJECTED,
    ],
  },
};

interface IsValidTransitionParams {
  previousStatus: undefined | null | string;
  currentStatus: string;
  nextStatus: string;
}

export const isValidTransition = ({
  previousStatus,
  currentStatus,
  nextStatus,
}: IsValidTransitionParams): boolean => {
  const conditions = [transitionMap[currentStatus] ?? []].flat();

  return conditions.some((condition) => {
    const matchesPreviousStatus =
      !('previousStatuses' in condition) ||
      (previousStatus && condition.previousStatuses.includes(previousStatus));

    return matchesPreviousStatus && condition.nextStatuses.includes(nextStatus);
  });
};
