import * as PaymentStatus from '@retail/order-constants/src/paymentStates/enum';
import * as FinancingType from '@retail/order-financing/src/constants/financingTypes/enum';

import { TableColumn, ApplicationType } from './types';

export const DEFAULT_TABLE_COLUMNS: TableColumn[] = [
  TableColumn.Country,
  TableColumn.OrderCreationDate,
  TableColumn.AppNumber,
  TableColumn.OrderNumber,
  TableColumn.CustomerName,
  TableColumn.AppState,
  TableColumn.Assignee,
  TableColumn.PaymentType,
  TableColumn.CreditAmount,
  TableColumn.CashAmount,
  TableColumn.BalloonAmount,
  TableColumn.CreditPeriod,
];

export const TABLE_COLUMN_LABELS: Record<TableColumn, string> = {
  [TableColumn.Country]: 'Country',
  [TableColumn.FlowType]: 'Flow type',
  [TableColumn.FinancingType]: 'Financing type',
  [TableColumn.AppState]: 'Application state',
  [TableColumn.AppType]: 'Application type',
  [TableColumn.OrderState]: 'Order state',
  [TableColumn.ExternalFinancingRefNumber]: 'Ext. financing ref #',
  [TableColumn.AppNumber]: 'Application No.',
  [TableColumn.OrderNumber]: 'Order No.',
  [TableColumn.StockNumber]: 'Stock No.',
  [TableColumn.Assignee]: 'Assignee',
  [TableColumn.ContractType]: 'Contract type',
  [TableColumn.PriorityRank]: 'Priority rank',
  [TableColumn.Cluster]: 'Cluster',
  [TableColumn.FinancingProvider]: 'Financing provider',
  [TableColumn.SalesAgent]: 'Sales agent',
  [TableColumn.InterestRate]: 'Interest rate',
  [TableColumn.CreditPeriod]: 'Credit period',
  [TableColumn.CreditAmount]: 'Credit amount',
  [TableColumn.PaymentType]: 'Payment type',
  [TableColumn.PaymentStatus]: 'Payment status',
  [TableColumn.MonthlyPaymentAmount]: 'Monthly payment amount',
  [TableColumn.BalloonAmount]: 'Balloon amount',
  [TableColumn.CashAmount]: 'Cash amount',
  [TableColumn.CustomerName]: 'Customer name',
  [TableColumn.Ppi]: 'PPI',
  [TableColumn.Gap]: 'GAP',
  [TableColumn.VerificationForm]: 'Verification form',
  [TableColumn.AppCreationDate]: 'Fin. app. creation date',
  [TableColumn.OrderCreationDate]: 'Order creation date',
  [TableColumn.HandoverDate]: 'Handover date',
  [TableColumn.WorkingDaysUntilHandover]: 'Working days until handover',
  [TableColumn.BankReleaseSigned]: 'Customer signed bank release',
  [TableColumn.BankSubmissionDate]: 'Bank submission date',
  [TableColumn.BankContractSignedOn]: 'Bank contract signed on',
};

export enum VerificationState {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export const VERIFICATION_STATE_LABELS: Record<VerificationState, string> = {
  [VerificationState.COMPLETED]: 'Completed',
  [VerificationState.INCOMPLETE]: 'Incomplete',
};

export const APPLICATION_TYPE_LABELS = {
  [ApplicationType.FastTrackApplication]: 'FastTrack',
  [ApplicationType.ApplicationBasedOnCompleteDocumentCheck]:
    'Based on complete document check',
};

export const PAYMENT_STATUS_LABELS = {
  [PaymentStatus.FULLY_PAID]: 'Fully paid',
  [PaymentStatus.FULLY_REFUNDED]: 'Fully refunded',
  [PaymentStatus.OPEN]: 'Open',
  [PaymentStatus.OVERPAID]: 'Overpaid',
  [PaymentStatus.PARTLY_PAID]: 'Partly paid',
  [PaymentStatus.REFUNDED]: 'Refunded',
};

export const FINANCING_TYPE_LABELS = {
  [FinancingType.INTERNAL]: 'Internal',
  [FinancingType.EXTERNAL]: 'External',
};
