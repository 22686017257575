import { useState } from 'react';
import DragOutlined from '@ant-design/icons/DragOutlined';
import ClearOutlined from '@ant-design/icons/ClearOutlined';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Row, Col, Space, Button, Tooltip, Modal, Table, Checkbox } from 'antd';

export { useColumnsConfig } from './useColumnsConfig';

import { TableColumn, ColumnConfig } from '../types';
import { TABLE_COLUMN_LABELS, DEFAULT_TABLE_COLUMNS } from '../constants';

import { getColumnsConfig } from './utils';
import { SortableContext, SortableRow } from './sortable';

interface ColumnsConfigProps {
  initialColumnsConfig: ColumnConfig[];
  onColumnsConfigApply: (config: ColumnConfig[]) => void;
  onClose: () => void;
}

interface ColumnsConfigModalProps extends ColumnsConfigProps {
  isOpen: boolean;
}

const ColumnsConfig = ({
  initialColumnsConfig,
  onColumnsConfigApply,
  onClose,
}: ColumnsConfigProps) => {
  const [config, setConfig] = useState(initialColumnsConfig);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || over.id === active.id) {
      return;
    }

    const activeIndex = config.findIndex((it) => it.column === active.id);
    const overIndex = config.findIndex((it) => it.column === over.id);

    setConfig(arrayMove(config, activeIndex, overIndex));
  };

  const handleVisibilityChange = (updated: TableColumn) => {
    setConfig(
      config.map((it) =>
        it.column === updated ? { ...it, isVisible: !it.isVisible } : it,
      ),
    );
  };

  const handleConfigRestore = () => {
    setConfig(getColumnsConfig(DEFAULT_TABLE_COLUMNS));
  };

  return (
    <SortableContext
      items={config.map((it) => it.column)}
      onDragEnd={handleDragEnd}
    >
      <Table<ColumnConfig>
        pagination={false}
        components={{ body: { row: SortableRow } }}
        style={{ marginBottom: 24 }}
        columns={[
          {
            title: 'Column name',
            render: (_, it) => (
              <span data-qa-selector="column-name">
                <DragOutlined />
                &nbsp;&nbsp;{TABLE_COLUMN_LABELS[it.column]}
              </span>
            ),
          },
          {
            title: 'Show',
            align: 'center',
            render: (_, it) => (
              <Checkbox
                checked={it.isVisible}
                data-qa-selector="column-visibility"
                onChange={() => handleVisibilityChange(it.column)}
              />
            ),
          },
        ]}
        dataSource={config}
        data-qa-selector="columns-config-table"
        onRow={(it) => ({
          id: it.column,
          'data-qa-selector': 'columns-config-table-row',
        })}
      />
      <Row justify="space-between">
        <Col>
          <Tooltip title="Restore default columns config">
            <Button
              icon={<ClearOutlined />}
              data-qa-selector="columns-config-restore"
              onClick={handleConfigRestore}
            />
          </Tooltip>
        </Col>
        <Col>
          <Space>
            <Button data-qa-selector="columns-config-cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              data-qa-selector="columns-config-apply"
              onClick={() => onColumnsConfigApply(config)}
            >
              Apply
            </Button>
          </Space>
        </Col>
      </Row>
    </SortableContext>
  );
};

export const ColumnsConfigModal = ({
  isOpen,
  ...props
}: ColumnsConfigModalProps) => (
  <Modal
    destroyOnClose
    title="Columns configuration"
    footer={null}
    visible={isOpen}
    wrapProps={{ 'data-qa-selector': 'columns-config-modal' }}
    onCancel={props.onClose}
  >
    <ColumnsConfig {...props} />
  </Modal>
);
